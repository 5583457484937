import React, { useRef } from 'react'
import "./Join.css"
import emailjs from '@emailjs/browser'

const Join = () => {
    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_xhy6lbo', 'template_ox48g6w', form.current,
        'WocpQUiE8m167YR2r')
           .then((result) => {
            console.log(result.text);
           }, (error) => {
            console.log(error.text)
           });
    }
  return (
    <div className="Join">
        <div className="left-j">
            <hr/>
            <div>
                <span>READY TO</span>
                <span className='strock-text'>LEVEL UP</span>
            </div>
            <div>
                <span>YOUR BODY</span>
                <span className='strock-text'>WITH US</span>
            </div>
        </div>
        <div className="right-j">
            
            <form ref={form} action="" className='email-container' onSubmit={sendEmail}>
                <input type="email" name="user_email" placeholder='Enter your email address' />
                <button className="btn btn-j">Join Now</button>
            </form>
        </div>
    </div>
  )
}

export default Join