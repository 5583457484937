import React from 'react'
import "./Plans.css"
import whiteTick from '../../assets/whiteTick.png'
import { plansData } from '../../data/plansData'

const Plans = () => {
  return (
    <div className="plans-container">

      <div className="blur plans-blur-1"></div>
      <div className="blur plans-blur-2"></div>
        <div className="programs-header">
            <span className='strock-text'>READY TO START</span>
            <span>YOUR JOURNEY</span>
            <span className='strock-text'>NOW WITHUS</span>
        </div>
        
        {/* plans card */}
        <div className="plans">
          {plansData.map((plan, i)=> (
            <div className="plan" key={1}>
              {plan.icon}
              <span>{plan.name}</span>
              <span>$ {plan.price}</span>

              <div className="features">
                {plan.features.map((feature, i)=> (
                  <div className="feature">
                    <img src={whiteTick} alt="" />
                    <span key={i}>{feature}</span>
                  </div>
                ))}
              </div>

              <div>
                <span>see more benefits -></span>
              </div>
              <button className='btn'>Join now</button>
            </div>
          ))}
        </div>
    </div>
  )
}

export default Plans